/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import {
  Affix,
  Badge,
  Button,
  Card,
  Center,
  Divider,
  Group,
  Loader,
  Table as MantineTable,
  Paper,
  Space,
  Stack,
  Text,
  Transition,
} from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import lodash from 'lodash';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowNarrowUp } from 'tabler-icons-react';
import {
  BillingStatement,
  BillingSummary,
  Statement,
  buildStatementData,
  buildSummaryData,
} from '../../../../business/events/billing';
import { TipoCodigo } from '../../../../business/events/general';
import { calcularPreco } from '../../../../business/proposals/estimate';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import PageSection from '../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../components/core/ProfileCardLink/ProfileCardLink';
import {
  EventStatusType,
  MovementBillingType,
  MovementBillingTypeCode,
  MovementTypeStandard,
  UnitOfMeasureType,
} from '../../../../models/core/cache.type';
import { EntityType } from '../../../../models/core/entities.type';
import { EventMeasurementType, EventType } from '../../../../models/core/events.type';
import entitiesService from '../../../../services/core/entities.service';
import eventsService from '../../../../services/core/events.service';
import theme from '../../../../theme';
import cacheUtils from '../../../../utils/cache.utils';
import { Feature } from '../../../../utils/constants.utils';
import { formatCurrency, formatDateToString } from '../../../../utils/formatter.utils';
import { newGuid } from '../../../../utils/helper.utils';

type Result = {
  loading: boolean;
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
    eventStatusTypes: EventStatusType[];
  } | null;
  data: {
    event: EventType;
    entity: EntityType | null;
    statement: BillingStatement;
    summary: BillingSummary[];
  } | null;
};

function EventView() {
  const navigate = useNavigate();
  const [scroll, scrollTo] = useWindowScroll();
  const refContent = useRef<any>();

  const { idEvento } = useParams();
  const queryParams = new URLSearchParams(useLocation().search);
  const idEntidade = Number(queryParams.get('entityId') || null);
  const perspectiva: 'client' | 'supplier' | 'internal' =
    (queryParams.get('perspective') as any) ?? 'internal';
  const perspectivaInterna = perspectiva === 'internal';

  const [result, setResult] = useState<Result>({
    loading: true,
    referenceData: null,
    data: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      let { referenceData, data } = result;
      try {
        if (result.referenceData === null) {
          referenceData = {
            unitOfMeasures: await cacheUtils.listUnityOfMeasures(),
            eventStatusTypes: await cacheUtils.listEventStatuses(),
          };
        }

        const event = await eventsService.select({ idEvento: Number(idEvento) });
        const statement = buildStatementData(event);
        let summary = buildSummaryData(statement);

        if (idEntidade) {
          if (perspectiva === 'supplier') {
            statement.extrato = statement.extrato.filter((x) => x.fornecedor.idEntidade === idEntidade);
          } else {
            statement.extrato = statement.extrato.filter(
              (x) => x.cliente.idEntidade === idEntidade || x.fornecedor.idEntidade === idEntidade
            );
          }

          summary = summary
            .filter((x) => x.clienteFornecedor.idEntidade === idEntidade)
            .map((y) => ({
              ...y,
              receita: perspectivaInterna ? y.receita : y.despesa,
              despesa: perspectivaInterna ? y.despesa : y.receita,
            }))
            .map((z) => ({
              ...z,
              balanco: z.receita - z.despesa,
            }));

          summary.forEach((x) => {
            x.faturamento = x.faturamento
              .map((y) => ({
                ...y,
                receita: perspectivaInterna ? y.receita : y.despesa,
                despesa: perspectivaInterna ? y.despesa : y.receita,
              }))
              .map((z) => ({
                ...z,
                balanco: z.receita - z.despesa,
              }));
          });

          if (summary.length === 0) {
            showNotification({
              title: 'Evento - Imprimir',
              message: 'Cliente/Entidade não pertence a esse evento.',
              color: 'red',
            });
            navigate(`/500`, {
              replace: true,
            });
            return;
          }
        } else {
          // safe check - by removing this, it partially works
          showNotification({
            title: 'Evento - Imprimir',
            message: 'Entidade inválida.',
            color: 'red',
          });
          navigate(`/500`, {
            replace: true,
          });
          return;
        }

        const entity = await entitiesService.select({
          idEntidade: idEntidade || Number(event.resumoJSON?.cliente?.idCliente ?? 0),
        });

        data = {
          event: event as unknown as EventType,
          entity,
          statement,
          summary,
        };
        // setTimeout(() => window.print(), 1500);
      } catch (error: any) {
        showNotification({
          title: 'Evento - Imprimir',
          message: error?.isBusinessException
            ? error.description
            : `Não foi possível carregar o evento ou a entidade.`,
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setResult({ loading: false, referenceData, data });
      }
    };

    if (result.data === null) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildView = useMemo(() => {
    if (!result?.data) {
      return <div />;
    }

    const buildSectionHeader = (description: string) => {
      return (
        <PageHeader
          feature={Feature.Home.Event}
          title={`Evento #${result.data!.event.idEvento}`}
          description={description}
          buttons={[]}
          padding={0}
        />
      );
    };

    const buildGeneral = () => {
      const { event } = result.data!;

      let eventDescription = event.automatico
        ? event.descricao
        : `${event.resumoJSON?.residuo?.residuoCliente || event.resumoJSON?.servico?.servico || '-'} | ${
            event.descricao
          }`;
      if (eventDescription.startsWith('- |')) {
        eventDescription = eventDescription.replace('- |', '');
      }

      return (
        <div style={{ pageBreakAfter: 'always' }}>
          {buildSectionHeader('Informações gerais.')}
          <Paper shadow="xs" p="md" withBorder>
            <PageSection size="lg" color={Feature.Home.Event.color} label="Geral" text="" />
            <Space h="xs" />

            <MantineTable withBorder withColumnBorders striped>
              <tbody>
                <tr>
                  <td>Empresa Responsável</td>
                  <td>
                    <Badge variant="outline">{event.empresa}</Badge>
                  </td>
                  <td>Fonte</td>
                  <td>
                    {event.idContrato
                      ? `Contrato (#${event.idContrato})`
                      : event.idProposta
                      ? `Proposta (#${event.idProposta})`
                      : event.idEntidade
                      ? `Entidade (#${event.idEntidade})`
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td>Audiência</td>
                  <td>
                    <Badge variant="outline">{event.eventoAudiencia}</Badge>
                  </td>
                  <td>Tipo</td>
                  <td>
                    <Badge variant="outline">{event.eventoTipo}</Badge>
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>
                    <Badge variant="outline">{event.eventoStatus}</Badge>
                  </td>
                  <td>Data</td>
                  <td>{formatDateToString(event.data)}</td>
                </tr>
                <tr>
                  <td>Descrição</td>
                  <td>{eventDescription}</td>
                  <td>Responsável</td>
                  <td>{event.responsavel}</td>
                </tr>
                <tr>
                  <td>Observação</td>
                  <td colSpan={3}>{event.observacao || '-'}</td>
                </tr>
              </tbody>
            </MantineTable>
          </Paper>
        </div>
      );
    };

    const buildClient = () => {
      const client = result.data!.entity!;

      const buildClientGeneral = () => {
        return (
          <MantineTable withBorder withColumnBorders striped>
            <tbody>
              <tr>
                <td>Razão Social / Nome</td>
                <td>{client.razaoSocial || client.nome}</td>
                <td>CNPJ / CPF</td>
                <td>
                  <Badge variant="outline">{client.cnpj || client.cpf}</Badge>
                </td>
              </tr>
              <tr>
                <td>Nome Fantasia</td>
                <td>{client.nomeFantasia || '-'}</td>
                <td>Inscrição Estadual</td>
                <td>{client.incricaoEstadual || '-'}</td>
              </tr>
              <tr>
                <td>Encontro de Contas?</td>
                <td>
                  <Badge variant="outline">{client.aceitaEncontroContas ? 'Sim' : 'Não'}</Badge>
                </td>
                <td>Web Site</td>
                <td>{client.webSite || '-'}</td>
              </tr>
            </tbody>
          </MantineTable>
        );
      };

      const buildPaymentMethod = () => {
        const nodes: ReactNode[] = [];

        for (const item of client.formasPagamento) {
          nodes.push(
            <tr>
              <td>{item.formaPagamento}</td>
              <td>{!item.prazoPagamentoDias ? '-' : `${item.prazoPagamentoDias} dia(s)`}</td>
              <td>{item.recebimento ? 'Recebimento' : 'Pagamento'}</td>
              <td>{item.cnpj || item.cpf}</td>
              <td>{item.nome}</td>
              <td>
                {item.codigoFormaPagamento === 'PX' && item.recebimento ? (
                  `Chave PIX: ${item.chavePix}`
                ) : item.codigoFormaPagamento === 'TB' && item.recebimento ? (
                  <div>
                    <div>Banco: {item.banco}</div>
                    <div>
                      Agência: {item.agencia} | Conta corrente: {item.contaCorrente}
                      {`${!item.digitoVerificador ? '' : `-${item.digitoVerificador}`}`}
                    </div>
                  </div>
                ) : (
                  '-'
                )}
              </td>
            </tr>
          );
        }

        return (
          <MantineTable withBorder withColumnBorders striped>
            <thead>
              <tr>
                <th>Forma</th>
                <th>Prazo</th>
                <th>Tipo</th>
                <th>CNPJ / CPF</th>
                <th>Beneficiário</th>
                <th>Dados do Pagamento</th>
              </tr>
            </thead>
            <tbody>{nodes}</tbody>
          </MantineTable>
        );
      };

      const buildContact = () => {
        const nodes: ReactNode[] = [];

        for (const item of client.contatos) {
          nodes.push(
            <tr>
              <td>{item.nome}</td>
              <td>{item.setor || '-'}</td>
              <td>{item.email || '-'}</td>
              <td>{item.telefone || '-'}</td>
              <td>{item.telefoneRamal || '-'}</td>
              <td>{item.celular || '-'}</td>
            </tr>
          );
        }

        return (
          <MantineTable withBorder withColumnBorders striped>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Setor</th>
                <th>E-mail</th>
                <th>Telefone</th>
                <th>Ramal</th>
                <th>Celular</th>
              </tr>
            </thead>
            <tbody>{nodes}</tbody>
          </MantineTable>
        );
      };

      const buildAddress = () => {
        const nodes: ReactNode[] = [];

        for (const item of client.enderecos) {
          nodes.push(
            <tr>
              <td>{item.enderecoTipo}</td>
              <td>{`${item?.logradouro}, ${item.numero}${
                item.complemento ? ` - ${item.complemento}` : ''
              }`}</td>
              <td>{`${item.cidade} / ${item.codigoEstado}`}</td>
              <td>{item.bairro}</td>
              <td>{item.cep}</td>
              <td>
                {item.contatos.map((x) => {
                  return (
                    <div>
                      {x.nome} ({x.telefone || x.celular || x.email || x.setor || '-'})
                    </div>
                  );
                }) || '-'}
              </td>
            </tr>
          );
        }

        return (
          <MantineTable withBorder withColumnBorders striped>
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Endereço</th>
                <th>Cidade / UF</th>
                <th>Bairro</th>
                <th>CEP</th>
                <th>Contato(s)</th>
              </tr>
            </thead>
            <tbody>{nodes}</tbody>
          </MantineTable>
        );
      };

      return (
        <div style={{ pageBreakAfter: 'always' }}>
          {buildSectionHeader('Informações do cliente/fornecedor.')}
          <Paper shadow="xs" p="md" withBorder>
            <PageSection size="lg" color={Feature.Home.Event.color} label="Cliente / Fornecedor" text="" />
            <Space h="xs" />

            {buildClientGeneral()}
            <Space h="xs" />
            <Divider my="sm" variant="dashed" />

            <PageSection size="lg" color={Feature.Home.Event.color} label="Endereços" text="" />
            <Space h="xs" />
            {buildAddress()}
            <Space h="xs" />
            <Divider my="sm" variant="dashed" />

            <PageSection size="lg" color={Feature.Home.Event.color} label="Formas de Pagamento" text="" />
            <Space h="xs" />
            {buildPaymentMethod()}
            <Space h="xs" />
            <Divider my="sm" variant="dashed" />

            <PageSection size="lg" color={Feature.Home.Event.color} label="Contatos" text="" />
            <Space h="xs" />
            {buildContact()}
          </Paper>
        </div>
      );
    };

    const buildBilling = () => {
      const resumoNodes: ReactNode[] = [];
      const extratoNodes: ReactNode[] = [];

      const { faturamento } = result.data!.event;

      const calcBalance = (item: Statement) => {
        let balanco = 0;
        let balancoReceita = true;
        let desconto = false;

        if (item.cliente.idEntidade === item.fornecedor.idEntidade) {
          balanco = item.movimentacao.receita - item.movimentacao.despesa;
          if (balanco < 0) {
            balancoReceita = false;
          }
          if (
            item.movimentacao.receita < 0 &&
            item.movimentacao.codigoMovimentacaoPadrao !== MovementTypeStandard.Compra
          ) {
            desconto = true;
          }
        } else {
          balanco =
            item.cliente.idEntidade === result.data!.entity!.idEntidade
              ? item.movimentacao.receita
              : item.movimentacao.despesa;

          if (item.cliente.idEntidade === idEntidade) {
            if (item.movimentacao.receita < 0) {
              balancoReceita = false;
              desconto = true;
            }
          } else if (item.movimentacao.despesa < 0) {
            desconto = true;
          } else {
            balancoReceita = false;
          }
        }

        if (!perspectivaInterna) {
          balancoReceita = !balancoReceita;
        }

        return { balanco, balancoReceita, desconto };
      };

      const getQuotationReference = (item: Statement) => {
        if (!item.cotacao) {
          return null;
        }

        const movement = item.movimentacao;
        const { cotacao } = item;
        let cotacaoString;

        const calcPreco = calcularPreco(
          cotacao.preco,
          cotacao.preco,
          cotacao.margem,
          cotacao.precoFinal,
          cotacao.imposto || 0,
          item?.residuo?.compra || false,
          cotacao.receita || false
        );
        let preco =
          item.fornecedor.idEntidade === result.data!.entity!.idEntidade
            ? calcPreco.preco
            : calcPreco.novoPrecoComMargem;

        if (movement.idEventoAcondicionamento || movement.idEventoEquipamento || movement.idEventoVeiculo) {
          cotacaoString = `${formatCurrency(preco)} | ${cotacao?.quantidade} ${
            cotacao?.quantidadeUnidadeMedida
          } / ${cotacao?.frequenciaUnidadeMedida}`;
        } else if (movement.idEventoTratamento) {
          cotacaoString = `${formatCurrency(preco)} | ${cotacao?.quantidade} ${
            cotacao?.quantidadeUnidadeMedida
          }`;
        } else if (
          movement.idEventoDestinoFinal ||
          movement.codigoMovimentacaoPadrao === MovementTypeStandard.Compra
        ) {
          preco =
            movement.codigoMovimentacaoPadrao === MovementTypeStandard.Compra
              ? calcPreco.preco
              : movement.codigoMovimentacaoPadrao === MovementTypeStandard.Venda
              ? calcPreco.novoPrecoComMargem
              : preco;

          cotacaoString = `${formatCurrency(preco)} | ${cotacao?.quantidade} ${
            cotacao?.quantidadeUnidadeMedida
          }`;
        } else if (movement.idEventoServico) {
          cotacaoString = `${formatCurrency(preco)} | ${cotacao?.quantidade} ${
            cotacao?.frequenciaUnidadeMedida1
          } / ${cotacao?.frequenciaUnidadeMedida2}`;
        }

        if (movement.idEventoTolerancia) {
          cotacaoString = `${formatCurrency(movement.tolerancia?.precoUnitario || 0)} / ${
            movement.tolerancia?.unidadeMedida
          } (após ${movement.tolerancia?.quantidade} ${movement.tolerancia?.unidadeMedida})`;
        }

        return cotacaoString;
      };

      if (faturamento) {
        // extrato
        const residuoServicoExtratoGroup = lodash.groupBy(result.data!.statement.extrato, 'residuoServico');
        const eventoExtratoGroup = lodash.groupBy(result.data!.statement.extrato, 'idEvento');

        const residuoServicoResumo = new Map();
        Object.keys(residuoServicoExtratoGroup).forEach((key) => {
          residuoServicoResumo.set(
            key,
            Object.values(
              result.data?.statement.extrato
                .filter((x) => x.residuoServico === key)
                .map((y) => ({ ...calcBalance(y), tipo: y.grupo }))
                .reduce((acc: any, item: any) => {
                  const { tipo, balanco, balancoReceita } = item;
                  if (!acc[tipo]) {
                    acc[tipo] = {
                      tipo,
                      despesa: 0,
                      receita: 0,
                      balanco: 0,
                    };
                  }
                  acc[tipo].despesa += balancoReceita ? 0 : balanco;
                  acc[tipo].receita += balancoReceita ? balanco : 0;

                  if (balancoReceita) {
                    acc[tipo].balanco += balanco;
                  } else {
                    acc[tipo].balanco -= balanco;
                  }

                  return acc;
                }, {})
            )
          );
        });

        const residuoServicoMedicaoResumo = new Map();
        Object.keys(residuoServicoExtratoGroup).forEach((key) => {
          residuoServicoMedicaoResumo.set(
            key,
            Object.values(
              result.data?.statement.extrato
                .filter((x) => x.residuoServico === key)
                .map((y) => y.medicao)
                .reduce((acc: any, item: EventMeasurementType) => {
                  const {
                    idEventoMedicao,
                    quantidadeOrigem,
                    quantidadeOrigemUnidadeMedida,
                    quantidadeDestino,
                    quantidadeDestinoUnidadeMedida,
                  } = item;

                  if (acc?.idEventoMedicao === idEventoMedicao) {
                    return acc;
                  }

                  if (quantidadeOrigemUnidadeMedida) {
                    if (!acc[quantidadeOrigemUnidadeMedida]?.quantidadeOrigemUnidadeMedida) {
                      acc[quantidadeOrigemUnidadeMedida] = {
                        idEventoMedicao,
                        quantidadeOrigemUnidadeMedida,
                        quantidadeOrigem: 0,
                      };
                    }
                    acc[quantidadeOrigemUnidadeMedida].idEventoMedicao = idEventoMedicao;
                    acc[quantidadeOrigemUnidadeMedida].quantidadeOrigem += quantidadeOrigem ?? 0;
                  }

                  if (quantidadeDestinoUnidadeMedida) {
                    if (!acc[quantidadeDestinoUnidadeMedida]?.quantidadeDestinoUnidadeMedida) {
                      acc[quantidadeDestinoUnidadeMedida] = {
                        ...acc[quantidadeDestinoUnidadeMedida],
                        idEventoMedicao,
                        quantidadeDestinoUnidadeMedida,
                        quantidadeDestino: 0,
                      };
                    }
                    acc[quantidadeDestinoUnidadeMedida].idEventoMedicao = idEventoMedicao;
                    acc[quantidadeDestinoUnidadeMedida].quantidadeDestino += quantidadeDestino ?? 0;
                  }

                  acc.idEventoMedicao = idEventoMedicao;
                  return acc;
                }, {})
            )
          );
        });

        const residuoServicoTotal = new Map();
        Array.from(residuoServicoResumo.keys()).forEach((key) => {
          const value = residuoServicoResumo.get(key);
          residuoServicoTotal.set(
            key,
            value.reduce(
              (acc: any, item: any) => {
                const { movimentacaoFaturamentoTipo, despesa, receita, balanco } = item;
                if (!acc[movimentacaoFaturamentoTipo]) {
                  acc[movimentacaoFaturamentoTipo] = {
                    movimentacaoFaturamentoTipo,
                    despesa: 0,
                    receita: 0,
                    balanco: 0,
                  };
                }
                acc.despesa += despesa;
                acc.receita += receita;
                acc.balanco += balanco;
                return acc;
              },
              { despesa: 0, receita: 0, balanco: 0 }
            )
          );
        });

        const eventoResumo = new Map();
        Object.keys(eventoExtratoGroup).forEach((key) => {
          eventoResumo.set(
            key,
            Object.values(
              result.data?.statement.extrato
                .filter((x) => x.idEvento === Number(key))
                .map((y) => ({ ...calcBalance(y), tipo: y.grupo }))
                .reduce((acc: any, item: any) => {
                  const { tipo, balanco, balancoReceita } = item;
                  if (!acc[tipo]) {
                    acc[tipo] = {
                      tipo,
                      despesa: 0,
                      receita: 0,
                      balanco: 0,
                    };
                  }
                  acc[tipo].despesa += balancoReceita ? 0 : balanco;
                  acc[tipo].receita += balancoReceita ? balanco : 0;
                  // acc[tipo].balanco += balanco;

                  if (balancoReceita) {
                    acc[tipo].balanco += balanco;
                  } else {
                    acc[tipo].balanco -= balanco;
                  }
                  return acc;
                }, {})
            )
          );
        });

        const eventoTotal = new Map();
        Array.from(eventoResumo.keys()).forEach((key) => {
          const value = eventoResumo.get(key);
          eventoTotal.set(
            key,
            value.reduce(
              (acc: any, item: any) => {
                const { despesa, receita, balanco } = item;

                acc.despesa += despesa;
                acc.receita += receita;
                acc.balanco += balanco;
                return acc;
              },
              { despesa: 0, receita: 0, balanco: 0 }
            )
          );
        });

        for (const key of Object.keys(residuoServicoExtratoGroup)) {
          const items = residuoServicoExtratoGroup[key];
          const item0 = items[0];
          const itemResiduoServicoTotal = residuoServicoTotal.get(items[0].residuoServico);
          const itemResiduoServicoMedicao = residuoServicoMedicaoResumo.get(items[0].residuoServico);

          extratoNodes.push(
            <tr>
              <td
                colSpan={6}
                style={{ paddingTop: 15, paddingBottom: 15, backgroundColor: theme.colors!.text![0] }}
              >
                <Group position="apart">
                  <Group spacing="lg">
                    {item0.residuo ? (
                      <ProfileCardLink
                        id={item0.residuo.idResiduo.toString()}
                        name={item0.residuo.residuoCliente}
                        nameSize="sm"
                        description={`Código IBAMA: ${item0.residuo.codigoIBAMA || '-'} | Perigoso: ${
                          item0.residuo.residuoClientePerigoso ? 'Sim' : 'Não'
                        }`}
                        descriptionSize="xs"
                        descriptionLength={100}
                        linkPrefix="residues"
                        avatar="R"
                        showLink
                      />
                    ) : item0.servico ? (
                      <ProfileCardLink
                        id={item0.servico!.idServico.toString()}
                        name={item0.servico!.servico}
                        nameSize="sm"
                        description={item0.servico!.servicoDescricao}
                        descriptionSize="xs"
                        linkPrefix="services"
                        avatar="S"
                        showLink
                      />
                    ) : (
                      <ProfileCardLink
                        id={item0.comissao!.idPropostaComissao!.toString()}
                        name="Comissão"
                        nameSize="sm"
                        avatar="C"
                        showLink={false}
                      />
                    )}
                    <Badge variant="outline">
                      Receita: {formatCurrency(itemResiduoServicoTotal.receita)}
                    </Badge>
                    <Badge variant="outline">
                      Despesa: {formatCurrency(itemResiduoServicoTotal.despesa)}
                    </Badge>
                    <Badge
                      variant="outline"
                      style={{
                        color:
                          itemResiduoServicoTotal.balanco > 0
                            ? 'green'
                            : itemResiduoServicoTotal.balanco === 0
                            ? 'orange'
                            : 'red',
                      }}
                    >
                      Balanço: {formatCurrency(itemResiduoServicoTotal.balanco)}
                    </Badge>
                  </Group>
                  <Group>
                    {itemResiduoServicoMedicao.map((x: any) => (
                      <>
                        {x.quantidadeOrigemUnidadeMedida && (
                          <Badge variant="outline">
                            Origem: {x.quantidadeOrigem.toFixed(3)} / {x.quantidadeOrigemUnidadeMedida}
                          </Badge>
                        )}
                        {x.quantidadeDestinoUnidadeMedida && (
                          <Badge variant="outline">
                            Destino: {x.quantidadeDestino.toFixed(2)} / {x.quantidadeDestinoUnidadeMedida}
                          </Badge>
                        )}
                      </>
                    ))}
                  </Group>
                </Group>
              </td>
            </tr>
          );

          const extratoSubNodes: ReactNode[] = [];
          const itemCountData = lodash.countBy(items, 'idEvento');
          const itemCountArray: number[] = [];

          for (const item of items) {
            const { balanco, balancoReceita, desconto } = calcBalance(item);

            let itemRowSpan;
            let itemEventoTotal;
            if (!itemCountArray.includes(item.idEvento)) {
              itemCountArray.push(item.idEvento);
              itemRowSpan = itemCountData[item.idEvento];
              itemEventoTotal = eventoTotal.get(item.idEvento.toString());
            }

            extratoSubNodes.push(
              <tr
                style={{
                  borderTop: itemRowSpan ? `0.25rem solid ${theme.colors!.primary![6]}` : undefined,
                }}
              >
                <td>{`${item.idEvento}.${item.movimentacao.idEventoMedicaoMovimentacao}`}</td>
                <td>
                  <Stack spacing="xs">
                    <ProfileCardLink
                      id={item.movimentacao.idEventoMedicaoMovimentacao?.toString() || '-'}
                      name={item.descricao}
                      nameSize="sm"
                      description={item.item}
                      descriptionLength={50}
                      descriptionSize="xs"
                      avatar={
                        item.grupo === MovementBillingTypeCode.GR
                          ? MovementBillingType.GerenciamentoResiduo
                          : item.grupo === MovementBillingTypeCode.LO
                          ? MovementBillingType.Locacao
                          : MovementBillingType.PrestacaoServico
                      }
                      showLink={false}
                    />
                    {item.servico?.observacao && (
                      <Text size="xs">
                        {'Obs: '}
                        {item.servico?.observacao}
                      </Text>
                    )}
                  </Stack>
                </td>
                {itemRowSpan && <td rowSpan={itemRowSpan}>{formatDateToString(item.data)}</td>}
                {itemRowSpan && (
                  <td rowSpan={itemRowSpan}>
                    {(item.documentos || [])?.length ? (
                      <Group spacing="xs">
                        {item
                          .documentos!.filter(
                            (x) =>
                              x.documentoTipo === 'Nota Fiscal' ||
                              x.documentoTipo === 'MTR' ||
                              x.documentoTipo === 'Romaneio de Coleta'
                          )
                          .map((y) => {
                            return (
                              <Badge
                                key={newGuid()}
                                variant="outline"
                              >{`${y.documentoTipo} - ${y.numeroDocumento}`}</Badge>
                            );
                          })}
                      </Group>
                    ) : (
                      '-'
                    )}
                  </td>
                )}
                {itemRowSpan && (
                  <td rowSpan={itemRowSpan}>{`${
                    item.medicao.quantidadeOrigem
                      ? `${item.medicao.quantidadeOrigem} ${item.medicao.quantidadeOrigemUnidadeMedida}`
                      : '-'
                  }`}</td>
                )}
                {itemRowSpan && (
                  <td rowSpan={itemRowSpan}>
                    {`${
                      item.medicao.quantidadeDestino
                        ? `${item.medicao.quantidadeDestino} ${item.medicao.quantidadeDestinoUnidadeMedida}`
                        : '-'
                    }`}
                  </td>
                )}
                {itemRowSpan && (
                  <td rowSpan={itemRowSpan}>{`${
                    item.cotacao?.minimoAceitavel
                      ? `${item.cotacao.minimoAceitavel} ${item.cotacao.minimoAceitavelUnidadeMedida} / ${item.cotacao.frequenciaUnidadeMedida}`
                      : item.residuo && item.residuo.minimoAceitavelIdUnidadeMedida1
                      ? `${item.residuo.minimoAceitavel} ${item.residuo.minimoAceitavelUnidadeMedida1} / ${item.residuo.minimoAceitavelUnidadeMedida2}`
                      : '-'
                  }`}</td>
                )}
                <td>{getQuotationReference(item) || '-'}</td>
                <td>{`${item.movimentacao.quantidade} ${item.movimentacao.quantidadeUnidadeMedida}`}</td>
                <td>{`${formatCurrency(Math.abs(balanco))} (${balancoReceita ? 'Receita' : 'Despesa'})${
                  desconto ? ` - Desconto` : ``
                }`}</td>
                {itemRowSpan && (
                  <td rowSpan={itemRowSpan}>
                    <Stack>
                      <Badge variant="outline">Receita: {formatCurrency(itemEventoTotal.receita)}</Badge>
                      <Badge variant="outline">Despesa: {formatCurrency(itemEventoTotal.despesa)}</Badge>
                      <Badge
                        variant="outline"
                        style={{
                          color:
                            itemEventoTotal.balanco > 0
                              ? 'green'
                              : itemEventoTotal.balanco === 0
                              ? 'orange'
                              : 'red',
                        }}
                      >
                        Balanço: {formatCurrency(itemEventoTotal.balanco)}
                      </Badge>
                    </Stack>
                  </td>
                )}
              </tr>
            );
          }

          extratoNodes.push(
            <tr>
              <td colSpan={9}>
                <Card>
                  <MantineTable className="custom-mantine-table" withBorder withColumnBorders striped>
                    <thead>
                      <tr>
                        <th>Tipo de Faturamento</th>
                        <th>Receita</th>
                        <th>Despesa</th>
                        <th>Balanço</th>
                      </tr>
                    </thead>
                    <tbody>
                      {residuoServicoResumo.get(items[0].residuoServico).map((x: any) => {
                        return (
                          <tr key={newGuid()}>
                            <td>{x.tipo}</td>
                            <td>{formatCurrency(x.receita)}</td>
                            <td>{formatCurrency(x.despesa)}</td>
                            <td
                              style={{
                                color: x.balanco > 0 ? 'green' : x.balanco === 0 ? 'orange' : 'red',
                              }}
                            >
                              {`${formatCurrency(x.balanco)}${
                                x.balanco > 0 ? ' (Recebimento)' : x.balanco < 0 ? ' (Pagamento)' : ''
                              }`}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </MantineTable>

                  <Divider my="sm" variant="dashed" />

                  <MantineTable className="custom-mantine-table" withBorder withColumnBorders>
                    <thead>
                      <tr>
                        <th colSpan={4}>Geral</th>
                        <th colSpan={3}>Medição</th>
                        <th colSpan={4}>Financeiro</th>
                      </tr>
                      <tr>
                        <th>Ref. #</th>
                        <th>Descrição</th>
                        <th>Data</th>
                        <th>Documentos</th>
                        <th>Quantidade de Origem</th>
                        <th>Quantidade de Destino</th>
                        <th>Mínimo Aceitável</th>
                        <th>Referência</th>
                        <th>Quantidade</th>
                        <th>Valor</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>{extratoSubNodes.map((x) => x)}</tbody>
                  </MantineTable>
                </Card>
              </td>
            </tr>
          );
        }

        // resumo
        for (const item of result.data!.summary) {
          item.clienteFornecedor = item.clienteFornecedor!;

          resumoNodes.push(
            <tr>
              <td>
                {item.clienteFornecedor.cnpj ? (
                  <ProfileCardLink
                    id={item.clienteFornecedor.idEntidade.toString()}
                    name={item.clienteFornecedor.nomeFantasia || item.clienteFornecedor.razaoSocial || '-'}
                    nameSize="sm"
                    description={
                      item.clienteFornecedor.nomeFantasia
                        ? item.clienteFornecedor.razaoSocial
                        : item.clienteFornecedor.cnpj
                    }
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                ) : (
                  <ProfileCardLink
                    id={item.clienteFornecedor.idEntidade.toString()}
                    name={item.clienteFornecedor.nome || '-'}
                    nameSize="sm"
                    description={item.clienteFornecedor.cpf}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                )}
              </td>
              <td>{formatCurrency(item.receita)}</td>
              <td>{formatCurrency(item.despesa)}</td>
              <td
                style={{
                  color: item.balanco > 0 ? 'green' : item.balanco === 0 ? 'orange' : 'red',
                }}
              >
                {`${formatCurrency(item.balanco)} ${
                  item.balanco > 0 ? ' (Recebimento)' : item.balanco < 0 ? ' (Pagamento)' : ''
                }`}
              </td>
              <td>{item.clienteFornecedor.aceitaEncontroContas ? 'Sim' : 'Não'}</td>
            </tr>
          );

          resumoNodes.push(
            <tr>
              <td colSpan={5}>
                <Card>
                  <MantineTable className="custom-mantine-table" withBorder withColumnBorders striped>
                    <thead>
                      <tr>
                        <th>Tipo de Faturamento</th>
                        <th>Receita</th>
                        <th>Despesa</th>
                        <th>Balanço</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.faturamento.map((x) => {
                        return (
                          <tr key={newGuid()}>
                            <td>{x.tipo}</td>
                            <td>{formatCurrency(x.receita)}</td>
                            <td>{formatCurrency(x.despesa)}</td>
                            <td
                              style={{
                                color: x.balanco > 0 ? 'green' : x.balanco === 0 ? 'orange' : 'red',
                              }}
                            >
                              {`${formatCurrency(x.balanco)} ${
                                x.balanco > 0 ? ' (Recebimento)' : x.balanco < 0 ? ' (Pagamento)' : ''
                              }`}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </MantineTable>
                </Card>
              </td>
            </tr>
          );
        }
      }

      return (
        <div style={{ pageBreakAfter: 'avoid' }}>
          {buildSectionHeader('Informações de faturamento.')}
          <Paper shadow="xs" p="md" withBorder>
            <PageSection
              size="lg"
              color={Feature.Home.Event.color}
              label="Resumo"
              text="Lista consolidada de todos os pagamentos e recebimentos associados à esse fechamento."
            />
            <Space h="xs" />
            <div>
              {faturamento ? (
                <div>
                  <MantineTable withBorder withColumnBorders striped>
                    <thead>
                      <tr>
                        <th>Cliente / Fornecedor</th>
                        <th>Receita</th>
                        <th>Despesa</th>
                        <th>Balanco</th>
                        <th>Encontro de Contas</th>
                      </tr>
                    </thead>
                    <tbody>{resumoNodes}</tbody>
                  </MantineTable>
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
            <Space h="xs" />
            <Divider my="sm" variant="dashed" />

            <PageSection
              size="lg"
              color={Feature.Home.Event.color}
              label="Extrato"
              text="Lista detalhada de todas as movimentações associadas à esse fechamento."
            />
            <Space h="xs" />
            <div>
              {faturamento ? (
                <div>
                  <MantineTable withBorder withColumnBorders>
                    <tbody>{extratoNodes}</tbody>
                  </MantineTable>
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          </Paper>
        </div>
      );
    };

    return (
      <div>
        {buildGeneral()}
        <Space h="lg" />

        {result.data.entity && (
          <>
            {buildClient()}
            <Space h="lg" />
          </>
        )}

        {result.data.event.codigoEventoTipo === TipoCodigo.Fechamento && <>{buildBilling()}</>}
      </div>
    );
  }, [idEntidade, perspectivaInterna, result.data]);

  return result.data === null ? (
    <Center>
      <Loader size="xl" />
    </Center>
  ) : (
    <Card ref={refContent} className="print" style={{ margin: 0, padding: 0 }}>
      <style>{`@media print {.print{zoom: 75%;}}`}</style>
      {buildView}
      <Affix position={{ bottom: 20, right: 20 }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <Button
              leftIcon={<ArrowNarrowUp size={18} />}
              style={transitionStyles}
              color="secondary"
              onClick={() => scrollTo({ y: 0 })}
            >
              Ir ao topo
            </Button>
          )}
        </Transition>
      </Affix>
    </Card>
  );
}

export default EventView;
